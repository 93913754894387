import "./App.css";

import "./css/bootstrap-dark.min.css";
import { Alert, ProgressBar, Row, Col, Form, InputGroup } from "react-bootstrap";

import { useEffect, useState } from "react";

import "chartjs-adapter-moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    SubTitle,
    TimeScale,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    SubTitle,
    TimeScale,
    annotationPlugin,
    Filler
);

function App() {
    const [updateInterval, setUpdateInterval] = useState(1); // seconds
    const [liveMetrics, setLiveMetrics] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastLiveMetricsLoad, setLastLiveMetricsLoad] = useState(0);
    const [isLiveMetricsOutdated, setIsLiveMetricsOutdated] = useState(false);

    const [historyData, setHistoryData] = useState({});
    const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
    const [lastHistoryLoad, setLastHistoryLoad] = useState(0);
    const [isHistoryOutdated, setIsHistoryOutdated] = useState(false);

    const [scaleAtZero, setScaleAtZero] = useState(false);

    // Running Tasks
    const [runningTasks, setRunningTasks] = useState(-1);
    const [runningTasksPB, setRunningTasksPB] = useState(30);
    const [runningTasksPBMax, setRunningTasksPBMax] = useState(30);
    const [runningTasksPBLabel, setRunningTasksPBLabel] = useState("N/A");
    const [runningTasksPBVariant, setRunningTasksPBVariant] = useState("danger");

    // Queued Tasks
    const [queuedTasks, setQueuedTasks] = useState(-1);
    const [queuedTasksPB, setQueuedTasksPB] = useState(60);
    const [queuedTasksPBMax, setQueuedTasksPBMax] = useState(60);
    const [queuedTasksPBLabel, setQueuedTasksPBLabel] = useState("N/A");
    const [queuedTasksPBVariant, setQueuedTasksPBVariant] = useState("danger");

    // Cancelled Tasks
    const [cancelledTasks, setCancelledTasks] = useState(-1);
    const [cancelledTasksPB, setCancelledTasksPB] = useState(60);
    const [cancelledTasksPBMax, setCancelledTasksPBMax] = useState(60);
    const [cancelledTasksPBLabel, setCancelledTasksPBLabel] = useState("N/A");
    const [cancelledTasksPBVariant, setCancelledTasksPBVariant] = useState("danger");

    // Chart Visibility
    const [show1MinuteChart, setShow1MinuteChart] = useState(false);
    const [show5MinuteChart, setShow5MinuteChart] = useState(true);
    const [show10MinuteChart, setShow10MinuteChart] = useState(true);
    const [show15MinuteChart, setShow15MinuteChart] = useState(true);
    const [show30MinuteChart, setShow30MinuteChart] = useState(true);
    const [show60MinuteChart, setShow60MinuteChart] = useState(true);
    const [show4HourChart, setShow4HourChart] = useState(false);
    const [show8HourChart, setShow8HourChart] = useState(false);
    const [show12HourChart, setShow12HourChart] = useState(false);
    const [showDayChart, setShowDayChart] = useState(false);

    useEffect(() => {
        // Update the live metrics every x seconds and abort if it takes longer than 750 millisecond

        const interval = setInterval(() => {
            const controller = new AbortController();

            setTimeout(() => {
                controller.abort();
            }, 750);

            fetch("https://queue-monitor-api.tas.gg/api/live-metrics", {
                signal: controller.signal,
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setLiveMetrics(result);
                        setIsLoaded(true);
                        setLastLiveMetricsLoad(Date.now());
                        setIsLiveMetricsOutdated(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }, updateInterval * 1000);

        return () => clearInterval(interval);
    }, [updateInterval]);

    useEffect(() => {
        // History
        fetch("https://queue-monitor-api.tas.gg/api/completed-tasks-history")
            .then((res) => res.json())
            .then(
                (result) => {
                    setHistoryData(result);
                    setIsHistoryLoaded(true);
                    setLastHistoryLoad(Date.now());
                },
                (error) => {
                    console.log(error);
                }
            );

        const interval = setInterval(() => {
            const controller = new AbortController();

            setTimeout(() => {
                controller.abort();
            }, 7500);

            fetch("https://queue-monitor-api.tas.gg/api/completed-tasks-history", { signal: controller.signal })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setHistoryData(result);
                        setIsHistoryLoaded(true);
                        setLastHistoryLoad(Date.now());
                        setIsHistoryOutdated(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, []);

    // Update UI
    useEffect(() => {
        if (!isLoaded) {
            return;
        }
        // Running Tasks
        const rt_warn = 20;
        const rt_danger = 25;
        const rt_max = 30;

        setRunningTasks(liveMetrics.started_tasks.metric_value);
        if (liveMetrics.started_tasks.metric_value >= rt_max) {
            setRunningTasksPBVariant("danger");
            setRunningTasksPBMax(rt_max);
            setRunningTasksPB(rt_max);
            setRunningTasksPBLabel(`WARNING: ${liveMetrics.started_tasks.metric_value} TASKS`);
        } else if (liveMetrics.started_tasks.metric_value >= rt_danger) {
            setRunningTasksPBVariant("danger");
            setRunningTasksPBMax(rt_max);
            setRunningTasksPB(liveMetrics.started_tasks.metric_value);
            setRunningTasksPBLabel(`${liveMetrics.started_tasks.metric_value}/${rt_max}`);
        } else if (liveMetrics.started_tasks.metric_value >= rt_warn) {
            setRunningTasksPBVariant("warning");
            setRunningTasksPBMax(rt_max);
            setRunningTasksPB(liveMetrics.started_tasks.metric_value);
            setRunningTasksPBLabel(`${liveMetrics.started_tasks.metric_value}/${rt_max}`);
        } else {
            setRunningTasksPBVariant("success");
            setRunningTasksPBMax(rt_max);
            setRunningTasksPB(liveMetrics.started_tasks.metric_value);
            setRunningTasksPBLabel(`${liveMetrics.started_tasks.metric_value}/${rt_max}`);
        }

        // Queued Tasks
        const qt_warn = 15;
        const qt_danger = 30;
        const qt_max = 45;

        setQueuedTasks(liveMetrics.not_started_tasks.metric_value);
        if (liveMetrics.not_started_tasks.metric_value >= qt_max) {
            setQueuedTasksPBVariant("danger");
            setQueuedTasksPBMax(qt_max);
            setQueuedTasksPB(qt_max);
            setQueuedTasksPBLabel(`WARNING: ${liveMetrics.not_started_tasks.metric_value} TASKS`);
        } else if (liveMetrics.not_started_tasks.metric_value >= qt_danger) {
            setQueuedTasksPBVariant("danger");
            setQueuedTasksPBMax(qt_max);
            setQueuedTasksPB(liveMetrics.not_started_tasks.metric_value);
            setQueuedTasksPBLabel(`${liveMetrics.not_started_tasks.metric_value}/${qt_max}`);
        } else if (liveMetrics.not_started_tasks.metric_value >= qt_warn) {
            setQueuedTasksPBVariant("warning");
            setQueuedTasksPBMax(qt_max);
            setQueuedTasksPB(liveMetrics.not_started_tasks.metric_value);
            setQueuedTasksPBLabel(`${liveMetrics.not_started_tasks.metric_value}/${qt_max}`);
        } else {
            setQueuedTasksPBVariant("success");
            setQueuedTasksPBMax(qt_max);
            setQueuedTasksPB(liveMetrics.not_started_tasks.metric_value);
            setQueuedTasksPBLabel(`${liveMetrics.not_started_tasks.metric_value}/${qt_max}`);
        }

        // Queued Tasks
        const ct_warn = 15;
        const ct_danger = 30;
        const ct_max = 45;

        setCancelledTasks(liveMetrics.cancelled_tasks.metric_value);
        if (liveMetrics.cancelled_tasks.metric_value >= ct_max) {
            setCancelledTasksPBVariant("danger");
            setCancelledTasksPBMax(ct_max);
            setCancelledTasksPB(ct_max);
            setCancelledTasksPBLabel(`WARNING: ${liveMetrics.cancelled_tasks.metric_value} TASKS`);
        } else if (liveMetrics.cancelled_tasks.metric_value >= ct_danger) {
            setCancelledTasksPBVariant("danger");
            setCancelledTasksPBMax(ct_max);
            setCancelledTasksPB(liveMetrics.cancelled_tasks.metric_value);
            setCancelledTasksPBLabel(`${liveMetrics.cancelled_tasks.metric_value}/${ct_max}`);
        } else if (liveMetrics.cancelled_tasks.metric_value >= ct_warn) {
            setCancelledTasksPBVariant("warning");
            setCancelledTasksPBMax(ct_max);
            setCancelledTasksPB(liveMetrics.cancelled_tasks.metric_value);
            setCancelledTasksPBLabel(`${liveMetrics.cancelled_tasks.metric_value}/${ct_max}`);
        } else {
            setCancelledTasksPBVariant("success");
            setCancelledTasksPBMax(ct_max);
            setCancelledTasksPB(liveMetrics.cancelled_tasks.metric_value);
            setCancelledTasksPBLabel(`${liveMetrics.cancelled_tasks.metric_value}/${ct_max}`);
        }
    }, [liveMetrics, isLoaded]);

    // Timer to check if live metrics are outdated
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLiveMetricsOutdated(true);
        }, updateInterval * 1000 * 5);
        return () => clearTimeout(timeout);
    }, [lastLiveMetricsLoad, updateInterval]);

    // Timer to check if history is outdated
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsHistoryOutdated(true);
        }, 10 * 1000 * 3);
        return () => clearTimeout(timeout);
    }, [lastHistoryLoad]);

    const tooltip1Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.byMinute[tooltipItems[0].dataIndex + 1].tasks_completed / 60).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip5Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by5Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 5 / 60).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by5Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 5).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip10Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by10Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 10 / 60).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by10Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 10).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by10Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 2).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip15Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by15Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 15 / 60).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by15Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 15).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by15Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 3).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.by15Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 1.5).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip30Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by30Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 30 / 60).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by30Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 30).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by30Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 6).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.by30Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 3).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.by30Minute[tooltipItems[0].dataIndex + 1].tasks_completed / 2).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip60Minute = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 60).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 60).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 12).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 6).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 4).toLocaleString() +
            " Tasks\n" +
            "30 Minute Average: " +
            (historyData.byHour[tooltipItems[0].dataIndex + 1].tasks_completed / 2).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip4Hour = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 60 / 4).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 4).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 12 / 4).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 6 / 4).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 4 / 4).toLocaleString() +
            " Tasks\n" +
            "30 Minute Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 2 / 4).toLocaleString() +
            " Tasks\n" +
            "1 Hour Average: " +
            (historyData.by4Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 4).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip8Hour = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 60 / 8).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 8).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 12 / 8).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 6 / 8).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 4 / 8).toLocaleString() +
            " Tasks\n" +
            "30 Minute Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 2 / 8).toLocaleString() +
            " Tasks\n" +
            "1 Hour Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 8).toLocaleString() +
            " Tasks\n" +
            "4 Hour Average: " +
            (historyData.by8Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 2).toLocaleString() +
            " Tasks"
        );
    };

    const tooltip12Hour = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 60 / 12).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 60 / 12).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 12 / 12).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 6 / 12).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 4 / 12).toLocaleString() +
            " Tasks\n" +
            "30 Minute Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 2 / 12).toLocaleString() +
            " Tasks\n" +
            "1 Hour Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 12).toLocaleString() +
            " Tasks\n" +
            "4 Hour Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 3).toLocaleString() +
            " Tasks\n" +
            "8 Hour Average: " +
            (historyData.by12Hour[tooltipItems[0].dataIndex + 1].tasks_completed / 1.5).toLocaleString() +
            " Tasks"
        );
    };

    const tooltipDay = (tooltipItems) => {
        return (
            "Averages:\n" +
            "1 Second Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 60 / 60 / 24).toLocaleString() +
            " Tasks\n" +
            "1 Minute Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 60 / 24).toLocaleString() +
            " Tasks\n" +
            "5 Minute Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 12 / 24).toLocaleString() +
            " Tasks\n" +
            "10 Minute Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 6 / 24).toLocaleString() +
            " Tasks\n" +
            "15 Minute Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 4 / 24).toLocaleString() +
            " Tasks\n" +
            "30 Minute Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 2 / 24).toLocaleString() +
            " Tasks\n" +
            "1 Hour Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 24).toLocaleString() +
            " Tasks\n" +
            "4 Hour Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 6).toLocaleString() +
            " Tasks\n" +
            "8 Hour Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 3).toLocaleString() +
            " Tasks\n" +
            "12 Hour Average: " +
            (historyData.byDay[tooltipItems[0].dataIndex].tasks_completed / 2).toLocaleString() +
            " Tasks"
        );
    };

    return (
        <div className="App">
            <Row className="justify-content-md-center m-1 m-md-5">
                <Col xs={12}>
                    <h1>Queue Monitor</h1>
                    {isLiveMetricsOutdated ? (
                        <Alert variant="danger">
                            <span class="fw-bold fs-3">Live Metrics Data is Out of Date</span>
                            <br />
                            <span class="fst-italic fs-5">
                                Your internet connection may be slow or unavailable, or the server may be down.
                            </span>
                        </Alert>
                    ) : null}

                    {isHistoryOutdated ? (
                        <Alert variant="danger">
                            <span class="fw-bold fs-3">History Data is Out of Date</span>
                            <br />
                            <span class="fst-italic fs-5">
                                Your internet connection may be slow or unavailable, or the server may be down.
                            </span>
                        </Alert>
                    ) : null}
                </Col>
            </Row>
            <Row className="justify-content-md-center m-1 m-md-5">
                <Col xs={6}>
                    <InputGroup>
                        <InputGroup.Text>Update Interval</InputGroup.Text>
                        <Form.Select
                            aria-label="Update Interval"
                            value={updateInterval}
                            onChange={(newInterval) => setUpdateInterval(newInterval.target.value)}
                        >
                            <option value={1}>1 Second</option>
                            <option value={2}>2 Seconds</option>
                            <option value={3}>3 Seconds</option>
                            <option value={4}>4 Seconds</option>
                            <option value={5}>5 Seconds</option>
                            <option value={10}>10 Seconds</option>
                            <option value={15}>15 Seconds</option>
                            <option value={30}>30 Seconds</option>
                            <option value={60}>60 Seconds</option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col xs={6}>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Scale Starts at Zero"
                        className="justify-content-start text-start"
                        value={scaleAtZero}
                        onChange={(newScaleAtZero) => setScaleAtZero(newScaleAtZero.target.checked)}
                    />
                </Col>
            </Row>
            <Row className="justify-content-md-center m-1 m-md-5">
                <Col
                    xs={12}
                    lg={4}
                >
                    <div className="fs-1 fw-bold">{runningTasks}</div>
                    <div>Running Tasks</div>
                    <ProgressBar
                        now={runningTasksPB}
                        max={runningTasksPBMax}
                        label={runningTasksPBLabel}
                        variant={runningTasksPBVariant}
                    />
                </Col>
                <Col
                    xs={12}
                    lg={4}
                >
                    <div className="fs-1 fw-bold">{queuedTasks}</div>
                    <div>Queued Tasks</div>
                    <ProgressBar
                        now={queuedTasksPB}
                        max={queuedTasksPBMax}
                        label={queuedTasksPBLabel}
                        variant={queuedTasksPBVariant}
                    />
                </Col>
                <Col
                    xs={12}
                    lg={4}
                >
                    <div className="fs-1 fw-bold">{cancelledTasks}</div>
                    <div>Cancelled Tasks</div>
                    <ProgressBar
                        now={cancelledTasksPB}
                        max={cancelledTasksPBMax}
                        label={cancelledTasksPBLabel}
                        variant={cancelledTasksPBVariant}
                    />
                </Col>
            </Row>
            {historyData && historyData.byMinute && historyData.byMinute.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show1MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow1MinuteChart(!show1MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By Minute (4 Hours)</h4>
                                {show1MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show1MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.byMinute.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.byMinute.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.byMinute.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.byMinute.slice(1, -1).map((data, index) => {
                                                        return data.tasks_completed;
                                                    }),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.byMinute[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.byMinute[historyData.byMinute.length - 2]
                                                            .timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip1Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.by5Minute && historyData.by5Minute.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show5MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow5MinuteChart(!show5MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By 5 Minutes (12 Hours)</h4>
                                {show5MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show5MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.by5Minute.slice(1, -1).map((data, index) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.by5Minute.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.by5Minute.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.by5Minute.slice(1, -1).map((data, index) => {
                                                        return data.tasks_completed;
                                                    }),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.by5Minute[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.by5Minute[historyData.by5Minute.length - 2]
                                                            .timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip5Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.by10Minute && historyData.by10Minute.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show10MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow10MinuteChart(!show10MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By 10 Minutes (24 Hours)</h4>
                                {show10MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show10MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.by10Minute.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.by10Minute.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.by10Minute.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.by10Minute
                                                        .slice(1, -1)
                                                        .map((data) => data.tasks_completed),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.by10Minute[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.by10Minute[historyData.by10Minute.length - 2]
                                                            .timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip10Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.by15Minute && historyData.by15Minute.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show15MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow15MinuteChart(!show15MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By 15 Minutes (36 Hours)</h4>
                                {show15MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show15MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.by15Minute.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.by15Minute.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.by15Minute.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.by15Minute
                                                        .slice(1, -1)
                                                        .map((data) => data.tasks_completed),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.by15Minute[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.by15Minute[historyData.by15Minute.length - 2]
                                                            .timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },

                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip15Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.by30Minute && historyData.by30Minute.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show30MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow30MinuteChart(!show30MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By 30 Minutes (48 Hours)</h4>
                                {show30MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show30MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.by30Minute.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.by30Minute.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.by30Minute.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.by30Minute
                                                        .slice(1, -1)
                                                        .map((data) => data.tasks_completed),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.by30Minute[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.by30Minute[historyData.by30Minute.length - 2]
                                                            .timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },

                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip30Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.byHour && historyData.byHour.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show60MinuteChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow60MinuteChart(!show60MinuteChart)}
                                role="button"
                            >
                                <h4>Completed Requests By Hour (72 Hours)</h4>
                                {show60MinuteChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show60MinuteChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.byHour.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.byHour.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.byHour.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.byHour
                                                        .slice(1, -1)
                                                        .map((data) => data.tasks_completed),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.byHour[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.byHour[historyData.byHour.length - 2].timestamp *
                                                            1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip60Minute,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "hour",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {historyData && historyData.by4Hour && historyData.by4Hour.length >= 4 && (
                <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show4HourChart ? "mb-md-5" : null}`}>
                    <Col
                        xs={12}
                        className="bg-dark rounded p-3 pb-0"
                    >
                        <Row>
                            <Col
                                xs={12}
                                onClick={() => setShow4HourChart(!show4HourChart)}
                                role="button"
                            >
                                <h4>Completed Requests By 4 Hours (5 Days)</h4>
                                {show4HourChart && <hr />}
                            </Col>
                        </Row>
                        <Row>
                            {isHistoryLoaded && show4HourChart ? (
                                <div
                                    className=""
                                    style={{ height: "300px" }}
                                >
                                    <Line
                                        data={{
                                            labels: historyData.by4Hour.slice(1, -1).map((data) => {
                                                return data.timestamp * 1000;
                                            }),
                                            datasets: [
                                                {
                                                    label: "Response Time",
                                                    data: historyData.by4Hour.slice(1, -1).map((data, index) => {
                                                        return data.response_time;
                                                    }),
                                                    borderColor: "#e74c3c",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Tries",
                                                    data: historyData.by4Hour.slice(1, -1).map((data, index) => {
                                                        return data.tries;
                                                    }),
                                                    borderColor: "#f39c12",
                                                    fill: false,
                                                    spanGaps: false,
                                                    tension: 0.1,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                    yAxisID: "y2",
                                                },
                                                {
                                                    label: "Requests",
                                                    data: historyData.by4Hour
                                                        .slice(1, -1)
                                                        .map((data) => data.tasks_completed),
                                                    borderColor: "#375a7f",
                                                    backgroundColor: "rgba(55,90,127,0.4)",
                                                    fill: true,
                                                    spanGaps: false,
                                                    tension: 0.5,
                                                    pointStyle: "circle",
                                                    pointRadius: 2,
                                                    pointHoverRadius: 5,
                                                },
                                            ],
                                        }}
                                        options={{
                                            interaction: {
                                                mode: "index",
                                                intersect: false,
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            stacked: false,
                                            plugins: {
                                                subtitle: {
                                                    display: true,
                                                    text: `${new Date(
                                                        historyData.by4Hour[1].timestamp * 1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })} - ${new Date(
                                                        historyData.by4Hour[historyData.by4Hour.length - 2].timestamp *
                                                            1000
                                                    ).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    })}`,
                                                    color: "rgb(255, 255, 255, 1)",
                                                    font: {
                                                        size: 10,
                                                        weight: "bold",
                                                    },
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        footer: tooltip4Hour,
                                                    },
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    type: "time",
                                                    time: {
                                                        unit: "day",
                                                    },
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                        color: "rgb(255, 255, 255, 0.5)",
                                                    },
                                                    min: scaleAtZero ? 0 : null,
                                                },
                                                y2: {
                                                    position: "right",
                                                    grid: {
                                                        color: "rgb(0, 0, 0, 0.75)",
                                                    },
                                                    min: 0,
                                                },
                                            },
                                            borderColor: "rgb(255, 255, 255, 1)",
                                        }}
                                    />
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            )}
            {/*<Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show8HourChart ? "mb-md-5" : null}`}>
                <Col
                    xs={12}
                    className="bg-dark rounded p-3 pb-0"
                >
                    <Row>
                        <Col
                            xs={12}
                            onClick={() => setShow8HourChart(!show8HourChart)}
                            role="button"
                        >
                            <h4>Completed Requests By 8 Hours (10 Days)</h4>
                            {show8HourChart && <hr />}
                        </Col>
                    </Row>
                    <Row>
                        {isHistoryLoaded && show8HourChart ? (
                            <div
                                className=""
                                style={{ height: "300px" }}
                            >
                                <Line
                                    data={{
                                        labels: historyData.by8Hour.slice(1, -1).map((data) => {
                                            return data.timestamp * 1000;
                                        }),
                                        datasets: [
                                            {
                                                label: "Response Time",
                                                data: historyData.by8Hour.slice(1, -1).map((data, index) => {
                                                    return data.response_time;
                                                }),
                                                borderColor: "#e74c3c",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Tries",
                                                data: historyData.by8Hour.slice(1, -1).map((data, index) => {
                                                    return data.tries;
                                                }),
                                                borderColor: "#f39c12",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Requests",
                                                data: historyData.by8Hour
                                                    .slice(1, -1)
                                                    .map((data) => data.tasks_completed),
                                                borderColor: "#375a7f",
                                                backgroundColor: "rgba(55,90,127,0.4)",
                                                fill: true,
                                                spanGaps: false,
                                                tension: 0.3,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                            },
                                        ],
                                    }}
                                    options={{
                                        interaction: {
                                            mode: "index",
                                            intersect: false,
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        stacked: false,
                                        plugins: {
                                            subtitle: {
                                                display: true,
                                                text: `${new Date(
                                                    historyData.by8Hour[1].timestamp * 1000
                                                ).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour12: true,
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })} - ${new Date(
                                                    historyData.by8Hour[historyData.by8Hour.length - 2].timestamp * 1000
                                                ).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour12: true,
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })}`,
                                                color: "rgb(255, 255, 255, 1)",
                                                font: {
                                                    size: 10,
                                                    weight: "bold",
                                                },
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    footer: tooltip8Hour,
                                                },
                                            },
                                        },
                                        scales: {
                                            x: {
                                                type: "time",
                                                time: {
                                                    unit: "day",
                                                },
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                                min: scaleAtZero ? 0 : null,
                                            },
                                            y2: {
                                                position: "right",
                                                grid: {
                                                    color: "rgb(0, 0, 0, 0.75)",
                                                },
                                                min: 0,
                                            },
                                        },
                                        borderColor: "rgb(255, 255, 255, 1)",
                                    }}
                                />
                            </div>
                        ) : null}
                    </Row>
                </Col>
            </Row>
            <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${show12HourChart ? "mb-md-5" : null}`}>
                <Col
                    xs={12}
                    className="bg-dark rounded p-3 pb-0"
                >
                    <Row>
                        <Col
                            xs={12}
                            onClick={() => setShow12HourChart(!show12HourChart)}
                            role="button"
                        >
                            <h4>Completed Requests By 12 Hours (15 Days)</h4>
                            {show12HourChart && <hr />}
                        </Col>
                    </Row>
                    <Row>
                        {isHistoryLoaded && show12HourChart ? (
                            <div
                                className=""
                                style={{ height: "300px" }}
                            >
                                <Line
                                    data={{
                                        labels: historyData.by12Hour.slice(1, -1).map((data) => {
                                            return data.timestamp * 1000;
                                        }),
                                        datasets: [
                                            {
                                                label: "Response Time",
                                                data: historyData.by12Hour.slice(1, -1).map((data, index) => {
                                                    return data.response_time;
                                                }),
                                                borderColor: "#e74c3c",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Tries",
                                                data: historyData.by12Hour.slice(1, -1).map((data, index) => {
                                                    return data.tries;
                                                }),
                                                borderColor: "#f39c12",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Requests",
                                                data: historyData.by12Hour
                                                    .slice(1, -1)
                                                    .map((data) => data.tasks_completed),
                                                borderColor: "#375a7f",
                                                backgroundColor: "rgba(55,90,127,0.4)",
                                                fill: true,
                                                spanGaps: false,
                                                tension: 0.3,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                            },
                                        ],
                                    }}
                                    options={{
                                        interaction: {
                                            mode: "index",
                                            intersect: false,
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        stacked: false,
                                        plugins: {
                                            subtitle: {
                                                display: true,
                                                text: `${new Date(
                                                    historyData.by12Hour[1].timestamp * 1000
                                                ).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour12: true,
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })} - ${new Date(
                                                    historyData.by12Hour[historyData.by12Hour.length - 2].timestamp *
                                                        1000
                                                ).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour12: true,
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })}`,
                                                color: "rgb(255, 255, 255, 1)",
                                                font: {
                                                    size: 10,
                                                    weight: "bold",
                                                },
                                            },
                                            tooltip: {
                                                callbacks: {
                                                    footer: tooltip12Hour,
                                                },
                                            },
                                        },
                                        scales: {
                                            x: {
                                                type: "time",
                                                time: {
                                                    unit: "day",
                                                },
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                                min: scaleAtZero ? 0 : null,
                                            },
                                            y2: {
                                                position: "right",
                                                grid: {
                                                    color: "rgb(0, 0, 0, 0.75)",
                                                },
                                                min: 0,
                                            },
                                        },
                                        borderColor: "rgb(255, 255, 255, 1)",
                                    }}
                                />
                            </div>
                        ) : null}
                    </Row>
                </Col>
            </Row>
            <Row className={`justify-content-md-center m-1 m-md-2 mx-md-5 ${showDayChart ? "mb-md-5" : null}`}>
                <Col
                    xs={12}
                    className="bg-dark rounded p-3 pb-0"
                >
                    <Row>
                        <Col
                            xs={12}
                            onClick={() => setShowDayChart(!showDayChart)}
                            role="button"
                        >
                            <h4>Completed Requests By Day (30 Days)</h4>
                            {showDayChart && <hr />}
                        </Col>
                    </Row>
                    <Row>
                        {isHistoryLoaded && showDayChart ? (
                            <div
                                className=""
                                style={{ height: "300px" }}
                            >
                                <Line
                                    data={{
                                        labels: historyData.byDay.map((data) => {
                                            return data.timestamp * 1000;
                                        }),
                                        datasets: [
                                            {
                                                label: "Response Time",
                                                data: historyData.byDay.map((data, index) => {
                                                    return data.response_time;
                                                }),
                                                borderColor: "#e74c3c",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Tries",
                                                data: historyData.byDay.map((data, index) => {
                                                    return data.tries;
                                                }),
                                                borderColor: "#f39c12",
                                                fill: false,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                                yAxisID: "y2",
                                            },
                                            {
                                                label: "Requests",
                                                data: historyData.byDay.map((data) => data.tasks_completed),
                                                borderColor: "#375a7f",
                                                backgroundColor: "rgba(55,90,127,0.4)",
                                                fill: true,
                                                spanGaps: false,
                                                tension: 0.1,
                                                pointStyle: "circle",
                                                pointRadius: 2,
                                                pointHoverRadius: 5,
                                            },
                                        ],
                                    }}
                                    options={{
                                        interaction: {
                                            mode: "index",
                                            intersect: false,
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        stacked: false,
                                        plugins: {
                                            subtitle: {
                                                display: true,
                                                text: `${new Date(historyData.byDay[0].timestamp * 1000).toLocaleString(
                                                    "en-US",
                                                    {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    }
                                                )} - ${new Date(
                                                    historyData.byDay[historyData.byDay.length - 1].timestamp * 1000
                                                ).toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour12: true,
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })}`,
                                                color: "rgb(255, 255, 255, 1)",
                                                font: {
                                                    size: 10,
                                                    weight: "bold",
                                                },
                                            },

                                            tooltip: {
                                                callbacks: {
                                                    footer: tooltipDay,
                                                },
                                            },
                                        },
                                        scales: {
                                            x: {
                                                type: "time",
                                                time: {
                                                    unit: "day",
                                                },
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    color: "rgb(255, 255, 255, 0.5)",
                                                },
                                            },
                                            y2: {
                                                position: "right",
                                                grid: {
                                                    color: "rgb(0, 0, 0, 0.75)",
                                                },
                                                min: 0,
                                            },
                                            min: scaleAtZero ? 0 : null,
                                        },
                                        borderColor: "rgb(255, 255, 255, 1)",
                                    }}
                                />
                            </div>
                        ) : null}
                    </Row>
                </Col>
                                </Row>*/}
        </div>
    );
}

export default App;
